import { useNavigate } from 'react-router-dom';
import createAuthRequest from './utils/createAuthRequest';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Stack, Typography, List, Switch, Fab, Avatar, Dialog, Box } from '@mui/material';
import ListingItemSummary from './components/ListingItemSummary';
import { Listing, Platform } from './models/listing';
import { useMemo } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useState } from 'react';
import { DashboardFilter, hideListingsNotActiveInOlx, applyFilters } from './filters';
import { useSnackbar } from './utils/error-toast-provider';
import { useCurrentSeller } from './contexts/sellerContext';
import { Add, Download, HideImageRounded } from '@mui/icons-material';
import { SpeedDialTooltipOpen } from './SpeedDialTooltipOpen';
import { VendoraEmailForm } from './VendoraEmailForm';
import { SellerPlatformId } from './models/seller';
import { CrossListingStatus } from './components/CrossListingStatus';
import olxLogo from './icons/olx-logo.png';
import vendoraLogo from './icons/vendora-logo.png';

export default function Dashboard() {
  const vendoraConnectionColorMap: { [key in SellerPlatformId['status']]?: 'green' | 'grey' | 'orange' } = {
    'connected': 'green',
    'none': 'grey',
    'pending': 'orange',
  };

  const vendoraConnectionStatusMap: { [key in SellerPlatformId['status']]?: string } = {
    'connected': 'Свързан',
    'pending': 'В процес на свързване'
  };

  const [filters, setFilters] = useState<DashboardFilter[]>([]);
  const [vendoraDialogOpen, setVendorDialogOpen] = useState(false);

  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const currentSeller = useCurrentSeller();

  const listingsQuery = useQuery({
    queryKey: ['listings'],
    queryFn: () => createAuthRequest<Listing[]>('listings', 'GET')()
  });

  const syncListingsQuery = useMutation({
    mutationFn: () => createAuthRequest('marketplaces/all/listings', 'PUT')(undefined, undefined, listings?.map(l => l.id)),
    onSuccess: (data) => {
      showSnackbar('Обявите бяха успешно синхронизирани', 'success');
      queryClient.setQueryData(['listings'], data)
    }
  });

  const listings = listingsQuery.data;

  const shouldShowOLXLoginButton = useMemo(() =>
    !currentSeller?.platformCredentials?.[Platform.Olx],
    [currentSeller]);

  const olxLoginRequest = useMutation({
    mutationFn: async () => {
      const response = await createAuthRequest<{ url: string }>('olx-auth/login', 'POST')();
      if (!!response) {
        window.location.assign(response.url);
      }
    }
  });

  const handleSwitchChange = (isChecked: boolean) => {
    if (isChecked) {
      setFilters([...filters, hideListingsNotActiveInOlx]);
    } else {
      setFilters(filters.filter(f => f !== hideListingsNotActiveInOlx));
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Stack sx={{ width: { sm: '80%' }, overflowX: 'hidden' }} direction='column' spacing={2} alignItems='center'>
        <Stack
          width={{ sm: '95%' }}
          direction='column'
          spacing={5}
          justifyContent='start'
          alignItems='center'>
          <Stack
            width='100%'
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent='start'
            alignItems='center'
            spacing={5}>
            <Typography
              component="h1"
              variant="h3"
              sx={{ fontWeight: 600 }}>{listings?.length ?? 'Всички'} обяви</Typography>

            {!!currentSeller &&
              <Stack justifySelf='end' display='flex' direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                {currentSeller.externalIds?.vendora ? <CrossListingStatus iconPosition='right' crossListing={{
                  platformLogo: vendoraLogo,
                  statusColor: vendoraConnectionColorMap[currentSeller.externalIds.vendora.status] ?? 'grey',
                  statusText: vendoraConnectionStatusMap[currentSeller.externalIds.vendora.status] ?? 'Не е свързан'
                }} />
                  : <Fab
                    variant='extended'
                    onClick={() => setVendorDialogOpen(true)}
                    color='primary'
                  >
                    <Avatar
                      src={vendoraLogo}
                      sx={{ width: 24, height: 24, mr: 1 }}
                      variant='rounded'
                    />
                    <Typography variant='body2'>Свържи Vendora профила си</Typography>
                  </Fab>
                }
                {currentSeller.platformCredentials[Platform.Olx] ?
                  <CrossListingStatus iconPosition='right' crossListing={{
                    platformLogo: olxLogo,
                    statusColor: 'green',
                    statusText: 'Свързан'
                  }} />
                  : <Fab
                    variant='extended'
                    onClick={() => olxLoginRequest.mutate()}
                  >
                    <Avatar
                      src={olxLogo}
                      sx={{ width: 24, height: 24, mr: 1 }}
                      variant='rounded'
                    />
                    <Typography variant='body2'>Свържи OLX профила си</Typography>
                  </Fab>
                }
              </Stack>
            }
          </Stack>

          <Stack width='100%' display={{ xs: 'none', sm: 'flex' }} spacing={1} direction={{ xs: 'column', sm: 'row' }} alignItems='center' justifyContent='start'>
            <Fab
              title='Създай обява'
              variant='extended'
              onClick={() => { navigate('/create'); navigate(0); }}
            >
              <Add sx={{ mr: 1 }} />
              <Typography variant='body2'>Създай обява</Typography>
            </Fab>
            <Fab
              variant='extended'
              color='primary'
              onClick={() => shouldShowOLXLoginButton ?
                olxLoginRequest.mutate() :
                navigate('/listings/import')}
            >
              <Download sx={{ mr: 1 }} />
              <Typography variant='body2'>Добави обявите си от OLX</Typography>
            </Fab>
            <Fab
              variant='extended'
            >
              <Typography variant='caption'>Скрий неактивните обяви в OLX</Typography>
              <Switch onChange={(_, checked) => handleSwitchChange(checked)}></Switch>
            </Fab>
            <Fab
              variant='extended'
              onClick={() => syncListingsQuery.mutate()}
            >
              <RefreshIcon sx={{ mr: 1 }}></RefreshIcon>
              <Typography variant='caption'>Обнови обявите от OLX</Typography>
            </Fab>
          </Stack>
        </Stack>

        <List sx={{ width: '95%' }}>
          {applyFilters(listings ?? [], filters)?.map((listing: Listing) =>
            <ListingItemSummary
              listing={listing}
              key={listing.id}
            />)}
        </List>

        <Dialog onClose={() => setVendorDialogOpen(false)} open={vendoraDialogOpen}>
          <VendoraEmailForm
            existingEmail={currentSeller?.externalIds?.vendora?.email}
            onEmailUpdated={() => { setVendorDialogOpen(false); navigate(0); }}
          />
        </Dialog>
      </Stack >
      <Stack
        display={{ xs: 'flex', sm: 'none' }}
      >
        <SpeedDialTooltipOpen
          actions={[
            { icon: <Add />, name: 'Създай обява', onClick: () => navigate('/create') },
            {
              icon: <Download />, name: 'Добави обявите си от OLX', onClick: () => shouldShowOLXLoginButton ?
                olxLoginRequest.mutate() :
                navigate('/listings/import')
            },
            { icon: <HideImageRounded />, name: 'Скрий некативните обяви от OLX', onClick: () => handleSwitchChange(true) },
            { icon: <RefreshIcon />, name: 'Обнови обявите от OLX', onClick: () => syncListingsQuery.mutate() },
          ]}
        />
      </Stack>
    </Box>
  )
}