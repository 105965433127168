import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles/createTheme';
import { Button } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';

const StyledMenuLink = styled(RouterLink)(({ theme }: { theme: Theme }) => ({
  textDecoration: 'none',
  padding: '0 !important',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

// Extend the ButtonProps to include the component prop
interface RouterLinkButtonProps extends ButtonProps {
  component?: React.ElementType;
  to: LinkProps['to'];
  state?: any;
};

const RouterLinkButton = styled(Button)<RouterLinkButtonProps>(({ theme }: { theme: Theme }) => ({}));

RouterLinkButton.displayName = 'RouterLinkButton';

export { StyledMenuLink, RouterLinkButton };