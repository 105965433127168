import { PropsWithChildren } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: "'Roboto', 'sans-serif'",
    fontSize: 16,
    fontWeightRegular: 400,
    fontWeightBold: 400,
    fontWeightMedium: 400
  },
  palette: {
    primary: {
      main: '#41436a',

    },
    secondary: {
      main: '#fe9677'
    }
  },
});

export default function AppTheme(props: PropsWithChildren) {
  return <ThemeProvider theme={theme}>
    {props.children}
  </ThemeProvider>
}