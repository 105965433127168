import { useEffect, useState } from 'react';
import { AttributeField, OutputType as OutputAttributeValue } from './AttributeField';
import createAuthRequest from './utils/createAuthRequest';
import { useQuery } from '@tanstack/react-query';
import { Box } from '@mui/material';
import { AttributeDeclaration, isInputAttribute, isNumericAttribute } from './models/category-specific-attribute';
import { useFormikContext } from 'formik';
import { object } from 'yup';
import { createAttributesValidationSchema, GenericObjectSchema } from './utils/validation-schemas';

type Props = {
  selectedCategory: string;
  initialValues?: { [key: string]: OutputAttributeValue },
  endpoint: (selectedCategory: string) => string;
  // need to have this callback, as we can't set the validation schema from the child component
  // useFormikContext does not provide access to validation functions & schema - it's a known issue
  attributesValidationSchemaLoaded: (schema: GenericObjectSchema) => void;
  attributesValidationSchemaApplied: boolean;
};

export const AttributesForm = ({ selectedCategory, initialValues, endpoint, attributesValidationSchemaLoaded, attributesValidationSchemaApplied }: Props) => {
  const attributesQuery = useQuery({
    queryKey: ['attributes', selectedCategory],
    queryFn: () => createAuthRequest<AttributeDeclaration[]>(endpoint(selectedCategory), 'GET')(),
  });

  const formik = useFormikContext();

  // Load the validation schema for the attribute declarations and pass it to the parent component
  useEffect(() => {
    if (!attributesQuery.data) {
      return;
    }
    const attributesValidationSchema = createAttributesValidationSchema(attributesQuery.data);
    attributesValidationSchemaLoaded(object().shape({ attributes: object().shape(attributesValidationSchema) }));
    formik.setFieldValue('attributes', attributeValues, true);
  }, [attributesQuery.data]);

  useEffect(() => {
    if (attributesValidationSchemaApplied) {
      formik.validateForm();
    }
  }, [attributesValidationSchemaApplied, initialValues]);

  useEffect(() => {
    setAttributeValues(initialValues ?? {});
  }, [initialValues]);

  const [attributeValues, setAttributeValues] = useState<{ [key: string]: OutputAttributeValue }>({});

  const onFieldValueUpdated = (attributeId: string, newFieldValue: OutputAttributeValue): void => {
    const newValue = { ...attributeValues, [attributeId]: newFieldValue };
    setAttributeValues(newValue);
    formik.setFieldValue('attributes', newValue);
  };

  if ((attributesQuery.data as any)?.statusCode === 500) {
    return <></>;
  }

  const attributeIsInputPredicate = (attribute: AttributeDeclaration) => isNumericAttribute(attribute) || isInputAttribute(attribute);

  const attributes = attributesQuery.data?.sort((a, b) => (b.options?.length ?? 0) - (a.options?.length ?? 0))

  const inputs = attributes?.filter(attributeIsInputPredicate);

  const dropdowns = attributes?.filter((attribute) => !attributeIsInputPredicate(attribute)
    && (attribute.options?.length ?? 0) > 8);

  const toggles = attributes?.filter((attribute) => !attributeIsInputPredicate(attribute) && (attribute.options?.length ?? 0) <= 8);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'start',
      padding: '0px',
      width: '100%'
    }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'start', width: '100%' }}>
        {!!inputs && <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto', columnGap: '20px', width: '100%' }}>
          {inputs?.map(attribute =>
            <AttributeField
              key={attribute.code}
              attribute={attribute}
              fieldValueUpdated={onFieldValueUpdated}
              initialValue={initialValues?.[attribute.code]}
            />)}
        </Box>
        }
        {
          !!dropdowns && dropdowns.map(attribute =>
            <AttributeField
              key={attribute.code}
              attribute={attribute}
              fieldValueUpdated={onFieldValueUpdated}
              initialValue={initialValues?.[attribute.code]}
            />
          )}
        {
          !!toggles && toggles.map(attribute =>
            <AttributeField
              key={attribute.code}
              attribute={attribute}
              fieldValueUpdated={onFieldValueUpdated}
              initialValue={initialValues?.[attribute.code]}
            />
          )
        }
      </Box>
    </Box>
  );
}
