import { ListItem, Typography, Box, Stack, Button } from '@mui/material';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { RouterLinkButton } from './StyledLink';
import { Link } from 'react-router-dom';
import { Listing } from '../models/listing';
import { useEffect, useState } from 'react';
import { RemoteImage } from '../models/image';
import { ItemCrossListingStatus } from './CrossListingStatus';
import { Delete } from '@mui/icons-material';
import { DeleteListingDialog } from '../DeleteListingDialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import createAuthRequest from '../utils/createAuthRequest';
import { useSnackbar } from '../utils/error-toast-provider';

export default function ListingItemSummary({ listing }: { listing: Listing }) {
  const [titleImage, setTitleImage] = useState<RemoteImage | undefined>();
  const hasImages = listing.images.length;

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (listing.images.length === 0) {
      return;
    }
    setTitleImage(listing.images.reduce(
      (prev, current) => (prev.order < current.order) ? prev : current,
      listing.images[0]));
  }, [listing.images]);

  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: (deletionParameters: { listingId: string, shouldDeleteOlxListing: boolean }) =>
      createAuthRequest(`listings/${deletionParameters.listingId}`, 'DELETE')
        (undefined, [['shouldDeleteOlxListing', `${deletionParameters.shouldDeleteOlxListing}`]]),
    onError: () => {
      showSnackbar('Възникна грешка при изтриването на обявата', 'error');
    },
    onSuccess: async (err, variables, context) => {
      showSnackbar('Обявата беше успешно изтрита', 'success');
      await queryClient.setQueryData(['listings'], (oldData: Listing[] | undefined) => {
        if (!oldData) {
          return oldData;
        }
        return oldData.filter((oldListing: Listing) => oldListing.id !== listing.id);
      });
    },
  });

  const [deleteListingDialogOpen, setDeleteListingDialogOpen] = useState(false);

  const handleDeleteListing = (deletionParameters?: { listing: Listing, shouldDeleteOlxListing: boolean }) => {
    setDeleteListingDialogOpen(false);
    if (!deletionParameters) {
      return;
    }
    return deleteMutation.mutate({
      listingId: deletionParameters.listing.id,
      shouldDeleteOlxListing: deletionParameters.shouldDeleteOlxListing
    });
  }

  return (
    <ListItem
      sx={{
        borderBottom: 1, borderColor: 'grey.500',
        flexDirection: { xs: 'column', sm: 'column' },
        paddingY: 2,
        paddingX: 0,
        width: '100%',
        alignItems: 'center',
      }}>

      <Stack width='100%' direction={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'center', sm: 'space-between' }} justifyContent={{ xs: 'center', sm: 'space-between' }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'center', sm: 'space-between' }}
          justifyContent={{ xs: 'center', sm: 'flex-end' }}
          spacing={{ xs: 1, sm: 2 }}
          maxWidth={{xs: '100%', sm:'50%'}}
        >
          <Box
            width={150}
            height='auto'
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ backgroundColor: !hasImages ? 'grey.300' : 'transparent', borderRadius: 1 }}
          >
            {hasImages ?
              (<img src={titleImage?.url} alt={listing.title} width="100%" height="100%" style={{ objectFit: 'cover', borderRadius: '4px' }} />) :
              (<ImageNotSupportedIcon />)}
          </Box>
          <Stack textAlign={{ xs: 'center', sm: 'left' }} flex={1} display='flex' flexDirection='column' justifyContent='space-between'>
            <Typography component="h2" variant="h5">{listing.title}</Typography>
            <Typography component="h2" variant='subtitle1'>{listing.price}лв</Typography>
          </Stack>
        </Stack>
        <Stack flexDirection='row' width='500px' justifyContent={{ xs: 'center', sm: 'space-between' }}>
          <Stack display={{ xs: 'none', sm: 'flex' }}>
            <ItemCrossListingStatus listing={listing} />
          </Stack>
          <Stack direction='row' spacing={1}>
            <RouterLinkButton
              sx={{ alignSelf: 'center' }}
              component={Link}
              to={`/listings/${listing.id}/edit/main`}
              variant="contained">
              Виж повече
            </RouterLinkButton>
            <Button disableRipple onClick={() => setDeleteListingDialogOpen(true)}>
              <Delete color='error' />
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <DeleteListingDialog
        listing={listing}
        onClose={handleDeleteListing}
        open={deleteListingDialogOpen} />
    </ListItem >
  );
}