import { Listing, OlxListing, OlxStatus } from "./models/listing";

export type DashboardFilter = (listings: Listing[]) => Listing[];

export const applyFilters = (listings: Listing[], filters: DashboardFilter[]): Listing[] => {
    return filters.reduce((acc, filter) => filter(acc), listings);
}

export const hideListingsNotActiveInOlx: DashboardFilter = (listings: Listing[]): Listing[] => {
    return listings.filter(listing => {
        const olxListing = listing?.crossListings?.olx as OlxListing;
        return (!!listing.externalIdentifiers?.olx && (olxListing && olxListing.status === OlxStatus.Active));
    });
}