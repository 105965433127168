import { Dialog, ToggleButton, ToggleButtonGroup, Button, Box, Container, Typography, Stack } from '@mui/material';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import createAuthRequest from './utils/createAuthRequest';
import { Listing, Platform } from './models/listing';
import { useNavigate } from 'react-router-dom';
import { timeDifference } from './utils/time-difference';
import { TextCrossListingStatus } from './components/CrossListingStatus';

type Props = {
  listing: Listing;
  onLoadForm: (platform: Platform) => void;
  openedForm: Platform;
  listingUpdated: (updatedListing: Listing | undefined) => void;
};

const platformToPlatformName = {
  [Platform.Olx]: 'OLX',
  [Platform.Vendora]: 'Vendora',
  [Platform.Main]: 'Основна форма',
}

function ConnectedPlatformButton({ platform, listing }: { platform: Platform, listing: Listing }) {

  const lastSynced = platform !== Platform.Main ? listing.crossListings?.[platform]?.lastSynced : undefined;

  return <ToggleButton sx={{ textTransform: 'none' }} value={platform}>
    <Stack sx={{ direction: 'column' }}>
      <Typography>{platformToPlatformName[platform]}</Typography>

      <TextCrossListingStatus listing={listing} platform={platform} />
      {
        lastSynced &&
        <Typography variant='body2'>{`Актуализирана:
          ${timeDifference(Date.now(), lastSynced)}`}
        </Typography>
      }
    </Stack>

  </ToggleButton>
}
export function FormSwitchToggle({ listing, onLoadForm, openedForm, listingUpdated }: Props): JSX.Element {
  const [createFormPromptOpened, setCreateFormPromptOpened] = useState<boolean>(false)
  const [requestedForm, setRequestedForm] = useState<Platform | undefined>(undefined)
  const addCrossListing = createAuthRequest<Listing>('marketplaces', 'POST')
  const addCrossListingMutation = useMutation({
    mutationFn: ({ listing, platform }: { listing: Listing, platform: Platform }) => {
      return addCrossListing(`${platform}/listings/${listing.id}`, undefined, { ...listing });
    },
    onSuccess: (data) => {
      loadForm(requestedForm);
      listingUpdated(data);
    },
  });

  const navigate = useNavigate();

  const loadForm = (newForm: Platform | undefined): void => {
    if (!newForm) {
      return;
    }
    setRequestedForm(undefined);
    navigate(`/listings/${listing.id}/edit/${newForm}`);
  }

  const handleCreateAndOpenForm = async (newForm: Platform): Promise<void> => {
    if (!!requestedForm) addCrossListingMutation.mutate({ listing: listing, platform: newForm });
  }

  const isFormExisting = (form: Platform): boolean => {
    return form === Platform.Main || !!listing.crossListings?.[`${form}`];
  }

  const handleFormSwitch = (_: React.MouseEvent<HTMLElement>, newForm: Platform): void => {
    if (!newForm) {
      return;
    }
    if (isFormExisting(newForm)) {
      loadForm(newForm);
      return;
    }

    setRequestedForm(newForm);
    setCreateFormPromptOpened(true);
  }
  return listing && (
    <>
      <ToggleButtonGroup
        orientation='vertical'
        onChange={handleFormSwitch}
        exclusive
        value={openedForm}
        color='primary'
        sx={{ display: 'flex', flexDirection: 'column', gap: '10px', borderRadius: '20px' }}
      >
        <ToggleButton value={Platform.Main}>Основна форма</ToggleButton>
        <ConnectedPlatformButton platform={Platform.Vendora} listing={listing} />
        <ToggleButton value={Platform.Olx}>OLX.bg</ToggleButton>
      </ToggleButtonGroup>
      {!!requestedForm &&
        <CreateFormPrompt
          form={requestedForm}
          open={createFormPromptOpened}
          onClose={() => { setCreateFormPromptOpened(false) }}
          onFormCreationRequest={() => { handleCreateAndOpenForm(requestedForm); setCreateFormPromptOpened(false); }} />}
    </>
  )
}

function CreateFormPrompt({ form, open, onClose, onFormCreationRequest }: { form: Platform, open: boolean, onClose: () => void, onFormCreationRequest: () => void }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '150px' }}>
        <Typography>Създай форма за {form}?</Typography>
        <Box display={'flex'} gap={'10px'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
          <Button sx={{ flex: 1 }} color='success' variant='contained' onClick={onFormCreationRequest}>Да</Button>
          <Button sx={{ flex: 1 }} variant='contained' onClick={onClose}>Не</Button>
        </Box>
      </Container>
    </Dialog >
  )
}

export function DummyFormSwitchToggle(): JSX.Element {
  return (
    <ToggleButtonGroup
      orientation='vertical'
      exclusive
      color='primary'
      value={Platform.Main}
      disabled={true}
    >
      <ToggleButton value={Platform.Main}>Основна форма</ToggleButton>
      <ToggleButton value={Platform.Vendora}>Vendora</ToggleButton>
      <ToggleButton value={Platform.Olx}>OLX.bg</ToggleButton>
    </ToggleButtonGroup>
  )
}